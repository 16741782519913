.react-select-small__input {
  font-size: 12px;
}

.react-select-small__control {
  min-height: auto !important;
}

.react-select-small__placeholder {
  font-size: 12px;
}

.react-select__option:hover {
  background-color: lightblue;
}

.react-select-small__indicators {
  display: none !important;
}
